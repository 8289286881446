<template>
  <div class="m-con" v-loading="loading">
    <div class="m-head-bg"></div>
	<div class="m-main u-container">
		<div class="m-main-title">
			<span>{{info.title || ''}}</span>
		</div>
		<div class="u-flex u-row-center m-main-font1 u-m-t-20">
			<img src="../assets/img/common/icon-time3.png" />
			<span class="u-m-l-10">{{info.created_at || ''}}</span>
		</div>
		<div class="m-main-text" v-html="info.detail || ''"></div>
	</div>
  </div>
</template>

<script>
	import {getNewsInformationDetail} from "@/api/news.js"
	export default {
		data(){
			return {
				info:{},
				loading:false,
			}
		},
		mounted() {
			console.log(this.$route);
			this.getInfo()
		},
		methods:{
			async getInfo(){
				this.loading = true
				let res = await getNewsInformationDetail({id:this.$route.query.id})
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.info = res.data
				}else{
					this.$message.error(res.message)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-con{
		width: 100%;
		height: 100%;
    background: linear-gradient(to bottom, #11a6ff, #2bb0ff, #5bc2ff, #91d6ff, #cbecff, #fdffff) !important;
		padding-bottom: 100px;
		.m-main{
			margin-top: 80px;
      background: #fff;
      padding: 50px;
      overflow: hidden;
			.m-main-title{
				text-align: center;
				font-size: 30px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #464646;
			}
			.m-main-font1{
				font-size: 16px;
				color: #999999;
				line-height: 16px;
			}
			.m-main-text{
				font-size: 16px;
				color: #474646;
				margin-top: 38px;
			}
		}
	}
	.m-head-bg{
		width: 100%;
		min-width: 1200px;
		height: 430px;
		background: url(../assets/img/common/m-head-bg.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}
</style>
